import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Script from "next/script";
import { useRouter } from "next/router";
import Head from "next/head";
import Link from "next/link";
import { ToastContainer } from "react-toastify";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { appNotificationConfig } from "common/notifications/config";
import { EXCLUDED_INDEX_ROUTES, isServer } from "common/utils";
import { MAIN } from "common/constants/pathRoutes";
import AppLayout from "modules/AppLayout";
import { MainOrgScheme } from "modules/Main/seo";
import { getSeoTags } from "seo";
import AuthProvider from "contexts/Auth";
import BreadcrumbsProvider from "contexts/Breadcrumbs";
import AppProvider from "contexts/App";
import { ModalProvider } from "contexts/Modal";
import globalStyles from "../styled";
import NoticePopup from "../components/NoticePopup";
import * as PATH from "../common/constants/pathRoutes";
const App = ({
  Component,
  pageProps
}) => {
  const router = useRouter();
  const [isShowCookiePopup, setIsShowCookiePopup] = useState();
  useEffect(() => {
    import("react-toastify/dist/ReactToastify.min.css");
    import("tippy.js/dist/tippy.css");
    import("tippy.js/themes/light-border.css");
  }, []);
  useEffect(() => {
    const isShowCookiePopupRow = !localStorage.getItem("isShownCookiePopup");
    setIsShowCookiePopup(isShowCookiePopupRow);
  }, []);
  const onCloseCookiePopup = useCallback(() => {
    setIsShowCookiePopup(false);
    localStorage.setItem("isShownCookiePopup", true);
  }, []);
  const isPrivateRoute = Boolean(EXCLUDED_INDEX_ROUTES.filter(route => router.asPath.includes(route)).length);
  return <>
            <style jsx global>
                {globalStyles}
            </style>
            <script key="window_script">window.dataLayer = window.dataLayer || []</script>
            {process.env.NODE_ENV === "production" && <Script key="gtm_script" strategy={"afterInteractive"} dangerouslySetInnerHTML={{
      __html: `
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');
                `
    }} />}
            {router.asPath === MAIN && <MainOrgScheme />}
            <Head>
                <meta name="robots" key="robots" content={isPrivateRoute ? process.env.NEXT_PUBLIC_META_DISALLOW_ROBOTS_TAG : process.env.NEXT_PUBLIC_META_ROBOTS_TAG} />
            </Head>

            <AppProvider>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY} scriptProps={{
        async: true,
        defer: true
      }}>
                    <AuthProvider>
                        <ModalProvider>
                            <BreadcrumbsProvider breadcrumbLinks={pageProps.breadcrumbLinks}>
                                <AppLayout>
                                    {isShowCookiePopup && <NoticePopup onClose={onCloseCookiePopup}>
                                            We use cookies to maintain website functionality,
                                            enhance our services, personalize user experience,
                                            analyze website performance, and process book search
                                            queries efficiently. For more details, please refer to
                                            our{" "}
                                            <Link href={PATH.PRIVACY} passHref>
                                                Privacy Policy
                                            </Link>
                                            .
                                        </NoticePopup>}
                                    {/* we need to setup seo above pages
                                     to avoid duplicated seo tags */}
                                    {getSeoTags({
                  path: router.asPath,
                  prefetchedData: {
                    ...pageProps
                  }
                })}
                                    <Component {...pageProps} />
                                </AppLayout>
                            </BreadcrumbsProvider>
                        </ModalProvider>
                    </AuthProvider>
                </GoogleReCaptchaProvider>
            </AppProvider>
            <ToastContainer {...appNotificationConfig} />
            {!isServer && <>
                    <Script key="beacon_script" strategy="lazyOnload" dangerouslySetInnerHTML={{
        // eslint-disable-next-line quotes,max-len
        __html: `setTimeout(() => {(!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){}));
                        (window.Beacon('init', 'e96c4489-688e-49b5-b6ea-4890807c9b9c'));
                        }, 5000);`
      }} />
                </>}
        </>;
};
export function reportWebVitals(metric) {
  console.info(metric);
}
App.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object
};
export default App;